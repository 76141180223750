import { HttpClient, HttpParams, HttpClientJsonpModule } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private httpClient : HttpClient, private httpClientJsonP : HttpClientJsonpModule) { }

  sendMail(email : string, object : string, text : string){
    if(email != undefined && this.isValid(email)){
    this.httpClient.post<void>('https://formspree.io/f/mbjwejpg', {
      "name":object,
      "email":email,
      "text":text
    }, {
      headers: {
        'Accept': '/*/',
        'Content-Type': 'application/json'
    }
    }).subscribe()
    window.alert('Email inviata correttamente!')
  }
  else{
    window.alert("Invalid email data!");
  }
}


isValid(email : string){
  let regex = RegExp(".*@[a-zA-Z]+\.[a-zA-Z]{2,3}")
  console.log(regex)
  console.log(regex.test(email))
  return regex.test(email)
}

subscribeToMailchimp(firstName, lastName, email){
  if (email != null) {
  let mailChimpEndpoint = 'https://cherryinternationalfoundation.us12.list-manage.com/subscribe/post-json?u=ef1e9dcff6b804870519252e1&id=1a67a01f52'
  // 5dc3004e00319d45f232e1c6f
    const params = new HttpParams()
      .set('FNAME', firstName)
      .set('LNAME', lastName)
      .set('EMAIL', email)
      .set('b_b736eb9e9077236cbd681a53b_4b66a82360', '');
    const mailChimpUrl = `${mailChimpEndpoint}&${params.toString()}`;
    return this.httpClient.jsonp(mailChimpUrl, 'c')
  }
  else{
    return null
  }
}
}

