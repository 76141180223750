import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from 'app/om/Article';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  constructor(private http : HttpClient, private route : ActivatedRoute) { }

  article : Article

  articles : Article[] = []

  fiveArticles : Article[] = []

  ngOnInit(): void {
    let parameter = parseInt(this.route.snapshot.paramMap.get('id'))
    this.http.get('assets/articles.json').toPromise().then((data : Article[])=>{
      for( let artic of data){
        if(parseInt(artic.id) != parameter){
        this.articles.push(artic)
      }
      else{
        this.article = artic
      }
      }
      this.fiveArticles = this.articles
      // for(let i = 0; i < 5; i++){
      //   this.fiveArticles[i
      // }
      // this.fiveArticles = this.articles.slice(0,4)
      // puts this article as last and then pop
      // let fin = this.fiveArticles[this.fiveArticles.length - 1]
      // this.fiveArticles[this.fiveArticles.length - 1] = this.fiveArticles[parameter-1]
      // this.fiveArticles[parameter - 1] = fin
      // this.fiveArticles.pop()
    })
  }

}
