import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './pages/homepage/homepage.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { NewsComponent } from './pages/news/news.component';
import { ArticleComponent } from './pages/article/article.component';
import { EventsComponent } from './pages/events/events/events.component';

const routes: Routes =[
    { path: '',                component: HomepageComponent},
    { path: 'about-us',        component: AboutUsComponent },
    { path: 'gallery',         component: GalleryComponent},
    { path: 'project',            component: NewsComponent},
    { path: 'article/:id',         component: ArticleComponent},
    { path: 'events',         component: EventsComponent},
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
