import { Location } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { MailService } from 'app/services/mail.service';
import * as Rellax from 'rellax';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  data : Date = new Date();
  focus;
  focus1;
  email : string
  obj : string
  textEmail : string
  emailSubscription: string
  firstname1 : string
  lastname1 : string
  email1 : string
  constructor(private renderer : Renderer2, public location:  Location, private mailService : MailService) { }

  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');
    
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    // navbar.classList.add('navbar-transparent');
  //   this.renderer.listen('window', 'scroll', (event) => {
  //     const number = window.scrollY;
  //     var _location = this.location.path();
  //     _location = _location.split('/')[2];
  //     if(this.location.path() == '/about-us' || this.location.path() == '/project'){
  //       navbar.classList.remove('navbar-transparent');
  //     }
  //     if (number > 150 || window.pageYOffset > 150 ) {
  //         navbar.classList.remove('navbar-transparent');
  //     } else if (_location !== 'login' && this.location.path() !== '/nucleoicons') {
  //         // remove logic
  //         navbar.classList.add('navbar-transparent');
  //     }
  // });
  }
  
  sendMail(){
    if(this.email !== null && this.obj !== null && this.textEmail !== null){
      this.mailService.sendMail(this.email, this.obj, this.textEmail)
      this.email = ''
      this.textEmail = ''
      this.obj = ''
    }
  }
  
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }

  subscribeNewsletter(){
    try{
    this.mailService.subscribeToMailchimp(this.firstname1, this.lastname1, this.emailSubscription).subscribe( (res) => {
      
    })
  }
  catch (e : any){
    alert("An error occurred!")
    window.location.reload()
  }
  finally {
    alert('Thanks for your subscription!')
    window.location.reload()
  }
  }
}
